const content = {
  title: 'Ratgeber - Arbeitgeberfinanzierte Altersvorsorge',
  headvisualHeadline:
    'Gut für Sie – Gut für Ihre Mitarbeiter: Die arbeitgeberfinanzierte Altersvorsorge',
  firstParagraphs: [
    'Sie wollen ein verantwortungsbewusster und attraktiver Arbeitgeber sein. Eine positive, kollegiale Stimmung und ein moderner Arbeitsplatz mit flachen Hierarchien sind Ihnen wichtig. Sie fordern und fördern Ihre Angestellten. Mit dieser Einstellung haben Sie bereits den Grundstein gelegt um im „War of Talents“ zu punkten.',
    'Oftmals reichen ein gutes Betriebsklima, vielfältige Weiterbildungsmöglichkeiten und aussichtsreiche Karrierechancen jedoch nicht mehr aus um Fachkräfte langfristig für sich zu gewinnen. Der Nettolohn ist häufig das wesentliche Kriterium bei der Wahl des Arbeitgebers. Doch das gilt nicht für alle Mitarbeiter: Gerade hoch qualifizierte Fachkräfte achten sehr wohl auf attraktive Zusatzleistungen wie die arbeitgeberfinanzierte Altersversorgung.',
    'Aufgrund der unkomplizierten Vorschriften bietet sich eine arbeitgeberfinanzierte Altersversorgung besonders in der Direktversicherung an. Hier schließt der Arbeitgeber als Versicherungsnehmer eine Rentenversicherung auf das Leben der Arbeitnehmer als versicherte Personen ab. Die Arbeitnehmer bzw. deren Hinterbliebene erhalten einen unmittelbaren Anspruch auf die Versorgungsleistungen.'
  ],
  headline:
    'Kennen Sie die finanziellen Vorteile einer arbeitgeberfinanzierten Altersversorgung?',
  secondParagraphs: [
    'Eine arbeitgeberfinanzierte Altersversorgung hat nicht nur Vorteile für die Mitarbeiterbindung und Ihr Image als Arbeitgeber, sondern überzeugt aufgrund der staatlichen Förderung auch aus finanzieller Sicht.',
    'Seit das Gesetz zur Verbesserung der betrieblichen Altersversorgung (BetrAVG) am 1. Januar 2002 eingeführt wurde, haben pflichtversicherte Arbeitnehmer das Recht auf eine betriebliche Altersversorgung aus Entgeltumwandlung. Das Gesetz spricht jedem Arbeitnehmer, Arbeiter, Angestellten, Auszubildenden, Mitglied des Vorstands einer AG sowie bestimmten Gesellschafter - Geschäftsführern einer GmbH das unumstößliche Recht zu, bis zu 4 % ihres monatlichen Bruttoeinkommens über eine sogenannte Entgeltumwandlung in eine Betriebsrente einzuzahlen.'
  ],
  vorteile: {
    headline: 'Welche Vorteile hat das für Ihre Mitarbeiter?',
    listItems: [
      'Aufbau einer zusätzlichen Rente für das Alter',
      'Sie sparen Steuern und Sozialabgaben',
      'Private Weiterführung nach Ausscheiden möglich',
      'Absicherung der Familie möglich',
      'Zuschuss durch Arbeitgeber'
    ]
  },
  vorteileArbeitgeber: {
    headline: 'Und was haben Sie davon?',
    listItems: [
      'Steuerersparnis: Die Beiträge zur arbeitgeberfinanzierten Altersversorgung sind als Betriebsausgaben abzugsfähig.',
      'Geringere Lohnnebenkosten: Die Beiträge sind sozialversicherungsfrei.',
      'Geringer Verwaltungsaufwand: Eine Versicherung kann die komplette Abwicklung übernehmen',
      'Keine Beitragspflicht zum Pensions - Sicherungsverein',
      'Freie Wahl zwischen klassischer und Fondsanlage',
      'Imagegewinn für das Unternehmen'
    ]
  }
};

export default content;
