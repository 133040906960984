import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';

import content from 'src/content/aktuelles/ratgeber/arbeitgeberfinanzierte-altersvorsorge';
import { HalvedContent, ContentBox, List, Paragraphs } from 'src/components';
import BackgroundImage from 'gatsby-background-image';

const RatgeberArbeitgeberfinanzierteAltersvorsorgePage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: "75"
    }}
  >
    <section>
      <div className="container container--simple children-margin">
        <Paragraphs items={content.firstParagraphs} />
        <h3>{content.headline}</h3>
        <Paragraphs items={content.secondParagraphs} />
      </div>
    </section>
    <section className="no-children-margin">
      <HalvedContent align="center">
        <BackgroundImage
          fluid={data.womanWithCurlyHairThinkingOnCouch.childImageSharp.fluid}
          className={"bg-right"}
        />
        <ContentBox
          background="gray-blue"
          headline={content.vorteile.headline}
          wider
        >
          <List items={content.vorteile.listItems} />
        </ContentBox>
      </HalvedContent>
      <HalvedContent align="center" swapped>
        <BackgroundImage
          fluid={data.manWithGlasesSittingAtOfficeDesk.childImageSharp.fluid}
          className={"bg-25"}
        />
        <ContentBox
          background="light-blue"
          headline={content.vorteileArbeitgeber.headline}
          wider
        >
          <List items={content.vorteileArbeitgeber.listItems} />
        </ContentBox>
      </HalvedContent>
    </section>
  </MainLayout>
);

export default RatgeberArbeitgeberfinanzierteAltersvorsorgePage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-iphone-laughing.jpg" }
    ) {
      ...image
    }
    womanWithCurlyHairThinkingOnCouch: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-curly-hair-thinking-on-couch.jpg" }
    ) {
      ...image
    }
    manWithGlasesSittingAtOfficeDesk: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-glases-sitting-at-office-desk.jpg" }
    ) {
      ...image
    }
  }
`;
